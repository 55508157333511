#about {
    margin-top: 150px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 15%;
    min-width: 15vw;
}

.about-title {
    font-size: 50px;
    color: var(--cloudy-white);
    font-family: "NTR Regular";
}

.about-info {
    display: flex;
    flex-direction: row;
}

canvas {
  display: flex;
}
.about-info ul {
    font-family: "NTR Regular";
    list-style-type: none;
    padding-left: 0px;
    display: grid;
    grid-template-columns: repeat(2, minmax(150px, 10px));
  }
  
.about-info li {
    position: relative;
    font-family: "NTR Regular";
    padding-left: 20px;
    font-size: 18px;
    color: var(--dark-gray);

}

.about-content {
    font-family: "NTR Regular";
    color: var(--dark-gray);
    max-width: 600px;
    font-size: 22px;
    text-align: justify;
    margin: 0;
    line-height: 1.5;
}

.skills li::before {
content: "▸ ";
color: var(--light-blue);
position: absolute;
left: 0;
}

.isLinked {
    text-decoration: none;
  }
  
.isLinked:hover {
    font-family: "NTR Regular";
    color: var(--cloudy-white);

}

.about-image img {
    max-width: 300px;
    height: auto;
    border-radius: 40px;
    margin-left: 30px;
    box-shadow: 6px 13px 23px -5px rgba(0, 0, 0, 0.46);
    border: none;
}
.about-image:hover {
    transform: translateY(-7px);
}


@media only screen and (max-width: 790px) {
    .about-image img {
      max-width: 200px;
    }
  }

@media only screen and (max-width: 1000px){
  .decaArt {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
    #about {
      height: unset;
      padding-left: unset;
      padding-right: unset;
    }
  
    .about-title {
        font-size: 40px;
    }
    .about-content {
      font-size: 18px;
      text-align: justify;
      max-width: 70vw;
    }

    .about-info {
        max-width: 70vw;
    }
  
    .about-content a,
    .about-content b {
      font-size: 17px;
      font-family: "NTR Regular";
      
    }

  
  }