/*MUI: https://mui.com/material-ui/material-icons/*/
:root {
  --cloudy-white: #ccd6f6; /*about me text, need darker text for main stuff*/
  --dark-gray: #8892b0; /*main stuff text*/
  --light-blue: #61dafb; /*highlights/specialtext/icons*/
  --background: #0a192f; /*background*/
  --highlight-color: #112b52; /*self-explanatory*/
}

::selection {
  background: var(--highlight-color);
}

.App {
  display: grid;
  
}

/*Debugging purposes*/
/*
div {
  border: 1px solid red;
}

*/


@font-face {
  font-family: 'NTR Regular';
  font-style: normal;
  font-weight: normal;
  src: local('NTR Regular'), url('./Font/NTR-Regular.ttf') format('truetype');
}

.fade-in-section {
  opacity: 0;
  transform: translateY(10vh);
  visibility: hidden;
  transition: opacity 0.6s ease-out, transform 1s ease-out;
  will-change: opacity, visibility;
}
.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

.special {
  font-family: "NTR Regular";
  color: var(--light-blue);
}


.sentence {
  font-size: 85px;
  color: var(--cloudy-white);
  font-family: "NTR Regular";
}

.title {
  font-size: 50px;
  color: var(--cloudy-white);
  font-family: "NTR Regular";
}

.sub-text {
  font-family: "NTR Regular";
  color: var(--dark-gray);
  font-size: 45px;
}

.body-text {
  font-family: "NTR Regular";
  color: var(--dark-gray);
  font-size: 22px;
  line-height: 1.5;
}

.footer-text {
  margin-top: -25px;
  text-align: center;
  font-size: 13px;
  color: var(--dark-gray);
  font-family: "NTR Regular";
}

.footer {
  top: 94%;
  left: 45%;
  margin-top: 90px;
  margin-bottom: 10px;
}