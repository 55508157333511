#experience {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 15%;
    min-width: 15vw;
}

.experience-info {
    max-width: 600px;
    margin-top: 20px;
    text-align: justify;
    text-justify: inter-word;
    font-family: "NTR Regular";
    color: var(--dark-gray);
    font-size: 22px;
    line-height: 1.5;
}
