:root {
    --buttonColor: var(--cloudy-white ); 
    --borderColor: #8892b0;
    --textColor: #162e7a;
    --backgroundColor: var(--dark-gray);
    --ColorTester: #8892b0;
    --selectedColor: #bfccf4;
}

#sideTabs {
  display: flex;
  flex-direction: column;
  position: fixed;
  margin-top: calc(50vh - 120px);
}

@keyframes ripple {
  to {
    transform: scale(4);
    opacity: 0;
  }
}



.bar {
  font-family: "NTR Regular";
  font-size: 1.3rem;
  letter-spacing: 0.1px;
  cursor: pointer;
  min-width: 110px;
  padding-left: 20px;
  text-align: left;
  position: relative;
  overflow: hidden;
}
.s {
  padding-left: 10px;
}

.projects, .about, .experience, .intro, .socials {
  color: var(--dark-gray);
}

.aboutSelected, .projectsSelected, .experienceSelected, .introSelected {
  color: var(--light-blue);
}

.projectsSelected::after,
.aboutSelected::after,
.experienceSelected::after,
.introSelected::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 5px;
  background-color: var(--light-blue);
  transition: width 0.2s ease-in-out;
}



.githubIcon,
.linkedinIcon,
.emailIcon {
  color: var(--light-blue);
  margin: 0px 0px 0px 8px;
}

.githubIcon:hover,
.linkedinIcon:hover,
.emailIcon:hover {
  color: var(--cloudy-white);
}

.socialsBar:hover {
  margin-top: calc(calc(100vh / 2) + 130px);
  background: var(--borderColor);
  color: var(--backgroundColor);
  font-family: "NTR Regular";
  font-size: 1.2rem;
  letter-spacing: 0.5px;
  position: fixed;
  transform: skewX(0deg);
  display: flex;
  justify-content: center;
}

.socialsBar:hover > a {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin: 0;
}

  



@media only screen and (max-width: 800px) {
    #sideTabs {
        display: none
    }
}