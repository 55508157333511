#intro {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 15%;
    padding-top: 19%;
    min-width: 15vw;   
}

@keyframes blink {
    0% {opacity: 1;}
    50% {opacity: 0;}
    100% {opacity: 1;}
}

.Typewriter__cursor {
    color: #61dafb;
    font-weight: bold;
    animation: blink 2s infinite;
}


.intro-sentence {
    font-size: 85px;
    text-align: justify;
    text-justify: inter-word;
    line-height: normal;
    color: var(--cloudy-white);
    font-family: "NTR Regular";
}

.intro-name {
    font-family: "NTR Regular";
    color: var(--light-blue);
}
.intro-rt {
    margin-top: -70px;
    text-align: justify;
    text-justify: inter-word;
    font-family: "NTR Regular";
    color: var(--dark-gray);
    font-size: 45px;
}

.intro-inspirational {
    max-width: 600px;
    margin-top: -15px;
    text-align: justify;
    text-justify: inter-word;
    font-family: "NTR Regular";
    color: var(--dark-gray);
    font-size: 22px;
    line-height: 1.5;
}


.MuiSvgIcon-root {
    fill: currentColor;
    width: 1em;
    height: 1em;
    display: inline-block;
    font-size: 1.6rem;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    user-select: none;
    text-align: center;
    justify-content: center;
    align-content: center;
    flex-direction: column;
}

.sayEmail {
    transform: translateY(25%);
}

.messageBox {
    display: inline-block;
    white-space: nowrap;
    border-radius: 4px;
    padding: 10px 30px;
    border: 1px solid var(--light-blue);
    font-size: 22px;
    font-weight: bolder;
    text-decoration: none;
    align-content: center;
}


.messageBox:hover {
    background-color: #1e314e;
    text-decoration-color: #ccd6f6;
}
  
.messageBox:hover .messageBoxText {
font-family: "NTR Regular";
color: var(--cloudy-white);
}
.messageBoxText {
font-family: "NTR Regular";
color: var(--light-blue);
}

@media only screen and (max-width: 600px) {
    body .intro-sentence {
        font-size: 45px;
        margin: 0;
        max-width: 90vw;
        margin-right: 60px;
        text-align: left;
      }
    body .intro-name {
        font-size: 45px;
    }
    body .intro-rt {
        line-height: 1.2;
        right: -50%;
        margin-top: -20px;
        font-size: 28px;
        text-align: left;
    }

    .intro-inspirational {
        margin-top: 10px;
        font-size: 18px;
    }
    .messageBox {
        font-size: 16px;
        justify-content: center;
    }
}

@media only screen and (max-width: 700px) {
    .intro-sentence {
        font-size: 75px;
        margin: 0;
        text-align: center;
        max-width: 80vw;
        margin-right: 60px;
        text-align: center;
      }
      .intro-name {
        font-size: 75px;
        margin: 0;
        text-align: center;
      }
      .intro-rt {
        margin: 0;
        margin-top: -60px;
        font-size: 40px;
      }
}

@media only screen and (max-width: 800px) {
  
    #intro {
        height: unset;
        padding-left: unset;
        padding-right: unset;
        display: flex;
      
    }
      .intro-inspirational {
        max-width: 500px;
        margin-right: 60px;
      }
  
    
  
    
}
